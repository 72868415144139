<template>
  <div class="p-checkbox" @click="onChange">
    <input
      :id="name"
      v-model="value"
      v-bind="{ ...$attrs, ...(inputAttributes as InputHTMLAttributes) }"
      :data-test="testAttribute"
      :class="['p-checkbox__field', `p-checkbox__field--${size}`]"
      type="checkbox"
      :error="errorState"
    />
    <label v-if="$slots.append" class="p-checkbox__append">
      <slot name="append" />
    </label>
  </div>
</template>

<script setup lang="ts">
import type { InputHTMLAttributes } from 'vue'
import { useField } from 'vee-validate'

const emit = defineEmits<{
  onChange: [value: any]
}>()

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Boolean,
    required: true,
  },
  multiSelect: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'md',
    validator: (val: string) => ['sm', 'md'].includes(val),
  },
})
const testAttribute = dataTest(props.name)
const name = toRef(props, 'name')
const modelValue = toRef(props, 'modelValue')
const { value, meta, setTouched } = useField(name, undefined, {
  initialValue: modelValue,
})

const inputAttributes = {
  name: props.name,
  disabled: props.disabled,
  multiSelect: props.multiSelect,
}

const errorState = computed<boolean>(() => !meta.valid && meta.touched)

const onChange = () => {
  value.value = !value.value
  setTouched(true)
  emit('onChange', value)
}
</script>
